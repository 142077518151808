import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [guestId, setGuestId] = useState<string>('');
  const [guestPw, setGuestPw] = useState<string>('');

  const navigation = useNavigate();

  const submitLogin = () => {
    if (guestId === 'admin' && guestPw === '12345678') {
      navigation('/dashboard');
    } else {
      alert('아이디 및 비밀번호를 확인해주세요');
    }
  };

  return (
    <div className="h-auto w-full bg-[#171717] py-60 sm:h-full sm:w-screen">
      <div className="mx-auto w-11/12 sm:grid sm:w-full">
        <div className="mx-auto my-0 h-[30rem] w-[40rem] rounded-lg border-4 border-[#666666]  bg-[#181B1F] py-[4rem]">
          <div className="mx-auto my-0 text-[#7e7b7b]">
            <div className="mx-auto w-fit py-8">
              <h2 className="text-[1.5rem] font-bold">방문자 정보 입력</h2>
            </div>
            <div className="mx-auto mb-8 w-fit text-center">
              <div className="w-3/5">
                <p className="mb-2 pl-2 text-left font-bold ">
                  사용자 아이디 입력 :
                </p>
              </div>
              <input
                type="text"
                onChange={(event) => setGuestId(event.target.value)}
                className="mx-auto my-0 w-[23rem] rounded-lg border-4 pl-3 text-lg font-semibold text-[#000000]"
              />
            </div>
            <div className="mx-auto w-fit text-center">
              <div className="w-3/5">
                <p className="mb-2 pl-2 text-left font-bold ">
                  사용자 비밀번호 입력 :
                </p>
              </div>
              <input
                type="password"
                onChange={(event) => setGuestPw(event.target.value)}
                className="mx-auto my-0 w-[23rem] rounded-lg border-4 pl-3 text-lg font-semibold text-[#000000]"
              />
            </div>
            <div className="mx-auto mt-6 w-fit text-center">
              <button
                className="rounded-lg border-2 bg-[#FFFFFF] px-11 py-2 font-bold"
                type="button"
                onClick={() => submitLogin()}
              >
                검색
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
